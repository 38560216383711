import React from 'react'
import "./Why.css"
import WhyCard from "../assets/wiw-image.jpg"
import { useTranslation } from "react-i18next";

const Why = () => {
  const {t , i18n} = useTranslation();
  return (
    <div className="why-us">
        <h3>{t("ourad")}</h3>
        <h1>{t("whyus")}</h1>
        <div className="why-card">
            <img src = {WhyCard} draggable='false'/>
        </div>
    </div>
  )
}

export default Why