import React from "react";
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import HospitalImage from "../assets/HospitalImage.jpg"
import Hero from "../components/Hero.js"
import Footer from "../components/Footer.js"
import HospitalInfo from "../components/HospitalInfo.js"
import HairTransplant from "../components/HairTransplant.js"
import Comment from "../components/Comment.js"
import TreatmentMethods from "../components/TreatmentMethods.js"
import HospitalSlider from "../components/HospitalSlider.js"

function Hospital (){
    const images = [
        '../assets/',
        'https://example.com/image2.jpg',
        'https://example.com/image3.jpg',
      ];
    return(
        <>
        <Info/>
        <Navbar/>
        <Hero
        cName="hero-mid"
        heroImg={HospitalImage}
        />
        <HospitalInfo/>
        <Comment/>
        <HospitalSlider/>
        <HairTransplant/>
        <TreatmentMethods/>
        <Footer/>
        </>
    )
}

export default Hospital;