import React from 'react';
import "./Techniques.css"
import TechniquesData from "./TechniquesData.js"
import Sapphire1 from "../assets/sapphire1.jpg"
import Sapphire2 from "../assets/sapphire2.jpeg"
import Sapphire3 from "../assets/sapphire3.jpeg"
import { useTranslation } from "react-i18next";


const Sapphire = () => {
      const {t , i18n} = useTranslation();
  return (
    <div className='techniques'>
        <TechniquesData
        className="first-technique"
        heading={t("sapphtheading1")}
        heading1=""
        text={t("sapphttext1")}
        img1={Sapphire1}
        />

        <TechniquesData
        className="first-technique-reverse"
        heading={t("sapphtheading2")}
        heading1={t("sapphtheading3")}
        text={t("sapphttext2")}
        img1={Sapphire2}
        />

        <TechniquesData
        className="first-technique"
        heading={t("sapphtheading4")}
        heading1=""
        text={t("sapphttext3")}
        img1={Sapphire3}
        />
    </div>
  )
}

export default Sapphire