import React from 'react';
import "./Techniques.css"
import TechniquesData from "./TechniquesData.js"
import Eyebrow1 from "../assets/eyebrowF.jpg"
import Eyebrow2 from "../assets/eyebrowS.jpg"
import Eyebrow3 from "../assets/et1.jpeg"
import { useTranslation } from "react-i18next";

const ETBody = () => {
  const {t , i18n} = useTranslation();
  return (
    <div className='techniques'>
        <TechniquesData
        className="first-technique"
        heading={t("etheading1")}
        heading1=""
        text={t("ettext1")}
        img1={Eyebrow1}
        />

        <TechniquesData
        className="first-technique-reverse"
        heading={t("etheading2")}
        heading1={t("etheading3")}
        text={t("ettext2")}
        img1={Eyebrow2}
        />

        <TechniquesData
        className="first-technique"
        heading={t("etheading4")}
        heading1=""
        text={t("ettext3")}
        img1={Eyebrow3}
        />
    </div>
  )
}

export default ETBody