import React from 'react';
import "./Techniques.css"
import TechniquesData from "./TechniquesData.js"
import Sdhi1 from "../assets/sdhi1.jpg"
import Sdhi2 from "../assets/sdhi2.jpg"
import Sdhi3 from "../assets/sdhi3.jpg"
import { useTranslation } from "react-i18next";

const SDHI = () => {
      const {t , i18n} = useTranslation();
  return (
    <div className='techniques'>
        <TechniquesData
        className="first-technique"
        heading={t("sdhihtheading1")}
        heading1={t("sdhihtheading2")}
        text={t("sdhihttext1")}
        img1={Sdhi1}
        />

        <TechniquesData
        className="first-technique-reverse"
        heading={t("sdhihtheading3")}
        heading1={t("sdhihtheading4")}
        text={t("sdhihttext2")}
        img1={Sdhi2}
        />

        <TechniquesData
        className="first-technique"
        heading={t("sdhihtheading5")}
        heading1=""
        text={t("sdhihttext3")}
        img1={Sdhi3}
        />
    </div>
  )
}

export default SDHI