import React from "react";
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import ContactImage from "../assets/ContatctImage.jpg"
import Hero from "../components/Hero.js"
import Footer from "../components/Footer.js"
import ContactForm from "../components/ContactForm.js"

function Contact (){
    return(
        <>
        <Info/>
        <Navbar/>
        <Hero
        cName="hero-mid"
        heroImg={ContactImage}
        />
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default Contact;