import React, { useState, useRef } from 'react';
import './Comment.css';
import { cData } from './cData';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import GoogleIcon from '@mui/icons-material/Google';
import { useTranslation } from "react-i18next";

const Comment = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const commentsRef = useRef(null);

  const handleNext = () => {
    const commentsLength = commentsRef.current.children.length;
    const newIndex = (currentIndex + 1) % commentsLength;
    setCurrentIndex(newIndex);
  };

  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + cData.length) % cData.length;
    setCurrentIndex(newIndex);
  };

  const {t , i18n} = useTranslation();

  return (
    <div className="comment-container">
      <div className="c-header">
        <h3>Comments</h3>
      </div>
      <div className="comments" ref={commentsRef}>
        {cData.slice(currentIndex, currentIndex + 3).map((c) => (
          <div className="comment" key={c.id}>
            <div className="c-g-icon">
              <div className="g-icon"><GoogleIcon style={{ padding: 0 }} /></div>
              <FormatQuoteIcon />
              <p>{t(c.comment)}</p>
            </div>
            <div className="users">
              <img src={c.avatar} alt={c.user} />
              <div>
                <span>{c.user}</span>
                <p>{t(c.date)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="slider-controls">
        <button className='prev-but-c' onClick={handlePrev} disabled={currentIndex === 0}>
          Previous
        </button>
        <button className='next-but-c' onClick={handleNext} disabled={currentIndex === cData.length - 3}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Comment;