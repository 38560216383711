import React from "react";
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import HtHero from "../components/HtHero.js"
import Footer from "../components/Footer.js"
import TreatmentMethods from "../components/TreatmentMethods.js"
import HairTransplant from "../components/HairTransplant.js"
import ETBody from "../components/ETBody.js"
import { useTranslation } from "react-i18next";

function EyebrowTransplant (){
    const {t , i18n} = useTranslation();

    return(
        <>
        <Info/>
        <Navbar/>
        <HtHero
        cName="hero-ht"
        title={t("ettitle")}
        text={t("ettext")}
        buttonText={t("freeha")}
        url="/freehairanalysis"
        btnClass="fha"
        />
        <ETBody/>
        <HairTransplant/>
        <TreatmentMethods/>
        <Footer/>
        </>    
    )
}

export default EyebrowTransplant;