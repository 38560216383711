import React from 'react'
import Navbar from "../components/Navbar.js";
import AboutImage from "../assets/AboutImage.jpg"
import Hero from "../components/Hero.js"
import Footer from "../components/Footer.js"
import FHAForm from "../components/FHAForm.js"
import Info from "../components/Info.js"

const FreeHairAnalysis = () => {
  return (
    <>
    
    <Info/>
    <Navbar/>
    <FHAForm/>
    
    </>
  )
}

export default FreeHairAnalysis