import React from 'react'
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import ImprintImage from "../assets/ImprintImage.jpg"
import Hero from "../components/Hero.js"
import Footer from "../components/Footer.js"
import ImprintData from "../components/ImprintData.js"

const Imprint = () => {
  return (
    <>
    <Info/>
    <Navbar/>
    <Hero
    cName="hero-mid"
    heroImg={ImprintImage}
    title="Imprint"
    />
    <ImprintData/>
    <Footer/>
    </>
  )
}

export default Imprint