import React from 'react'
import './Info.css'
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';


const Info = () => {
    const isMobile = window.innerWidth <= 767;
  return (
    <div className='info-bar'>
        <div className='info-contact'>           
            <LocalPhoneIcon {...isMobile ? ({sx : { fontSize : 15 }}) : ({sx :{ fontSize : 25 }})}/><span>+90 530 604 8558</span>
            <EmailIcon {...isMobile ? ({sx : { fontSize : 15 }}) : ({sx :{ fontSize : 25 }})}/><span>info@hairlandistanbul.com</span> 
        </div>
        <div className='info-social'>
            <a href="https://m.facebook.com/hairlandistanbul">
                <i className="fa-brands fa-facebook-square"></i>
            </a>
            <a href="https://instagram.com/hairlandistanbul?igshid=OGQ5ZDc2ODk2ZA==">
                <i className="fa-brands fa-instagram-square"></i>
            </a>
            <a href="/">
                <i className="fa-brands fa-twitter-square"></i>
            </a>
        </div>
    </div>
  )
}

export default Info