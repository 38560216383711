export const faqData = [
    {
        question: "question1",
        answer: "answer1"
    },
    {
        question: "question2",
        answer: "answer2"
    },
    {
        question: "question3",
        answer: "answer3"
    },
    {
        question: "question4",
        answer: "answer4"
    },

    {
        question: "question5",
        answer: "answer5"
    },
    {
        question: "question6",
        answer: "answer6"
    },


    {
        question: "question7",
        answer: "answer7"
    },
    {
        question: "question8",
        answer: "answer8"
    },
    {
        question: "question9",
        answer: "answer9"
    },


    {
        question: "question10",
        answer: "answer10"
    },
    {
        question: "question11",
        answer: "answer11"
    },
    {
        question: "question12",
        answer: "answer12"
    },

    {
        question: "question13",
        answer: "answer13"
    },
    {
        question: "question14",
        answer: "answer14"
    },
    {
        question: "question15",
        answer: "answer15"
    },
    {
        question: "question16",
        answer: "answer16"
    },
    {
        question: "question17",
        answer: "answer17"
    },
    {
        question: "question18",
        answer: "answer18"
    },
    {
        question: "question19",
        answer: "answer19"
    },
    {
        question: "question20",
        answer: "answer20"
    },
    {
        question: "question21",
        answer: "answer21"
    },

    {
        question: "question22",
        answer: "answer22"
    },
    {
        question: "question23",
        answer: "answer23"
    },
    {
        question: "question24",
        answer: "answer24"
    },

];