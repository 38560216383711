import React from 'react'
import {Link} from "react-router-dom"
import "./Footer.css"
import Logo from "../assets/logo.png"
import Visa from '../assets/visa-logo.jpg'
import Paypal from '../assets/paypal-logo.jpg'
import Mastercard from '../assets/master-logo.jpg'
import { useTranslation } from "react-i18next";


const Footer = () => {
    const {t , i18n} = useTranslation();
  return (
    <div className="footer">
         <div className="left">
            <div className="left-top">
                
                <img className='f-logo' src={Logo}/>
                
                <div className="info">             
                    <i className="fa-solid fa-location-dot"></i>
                    <p>Huzur Mh., Azerbaycan Cd. No:4, 34415 Sarıyer/İstanbul</p>
                </div>
                <div className="info"> 
                    <i className="fa-solid fa-phone"></i>
                    <p>+90 530 604 8558</p>
                </div>
                <div className="info">
                    <i className="fa-solid fa-envelope"></i>
                    <p>info@hairlandistanbul.com</p>
                </div>
            </div>
            <div className="left-bottom">
                <a href="https://m.facebook.com/hairlandistanbul">
                    <i className="fa-brands fa-facebook-square"></i>
                </a>
                <a href="https://www.instagram.com/hairlandistanbul/">
                    <i className="fa-brands fa-instagram-square"></i>
                </a>
                <a href="/">
                    <i className="fa-brands fa-twitter-square"></i>
                </a>
            </div>
        </div>
        <div className="right">
            <div className="right-top">
                <div>
                    <h4>{t("overview")}</h4>
                    <Link className="links" to="/about">{t("about")}</Link>
                    <Link className="links" to="/hospital">{t("hospital")}</Link>
                    <Link className="links" to="/contact">{t("contact")}</Link>
                </div>
                <div>
                    <h4>{t("tmheading")}</h4>
                    <Link className="links" to="/hair-transplant">{t("hairt")}</Link>
                    <Link className="links" to="/beard-transplant">{t("beardt")}</Link>
                    <Link className="links" to="/eyebrow-transplant">{t("eyebrowt")}</Link>
                </div>
                <div>
                    <h4>{t("httech")}</h4>
                    <Link className="links" to="/fue">{t("fue")}</Link>
                    <Link className="links" to="/sapphire">{t("sapphire")}</Link>
                    <Link className="links" to="/dhi">{t("dhi")}</Link>
                </div>
            </div>
            <hr/>
            <div className="right-bottom">          
                <Link className="r-links" to="/faq">FAQ</Link> |
                <Link className="r-links" to="/imprint">{t("imprint")}</Link> |
                <Link className="r-links" to="/privacy">{t("privacy")}</Link>
            </div>
            <div className='logos'>
                <img className='logo-p' src = {Paypal} draggable = 'false'/>
                <img className='logo-p' src = {Visa} draggable = 'false'/>
                <img className='logo-p' src = {Mastercard} draggable = 'false'/>              
            </div>
        </div>
    </div>
  )
}

export default Footer