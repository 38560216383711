import React from 'react';
import {Link} from "react-router-dom"
import './TreatmentMethods.css';
import {tmData} from './tmData';
import { useTranslation } from "react-i18next";


const HairTransplant = () => {
    const {t , i18n} = useTranslation();
    return (
        <div className="t-methods" id="t-methods">
            <div className="tm-header">
                <h3>{t("tmheading")}</h3>
            </div>

            <div className="tm-categories">
                {tmData.map((tm) =>
                    <div className="tm-category" style={{backgroundImage : `url(${tm.image})`}}>
                        
                        <span>{t(tm.heading)}</span>
                        <div className="tm-view-more"><button className='tm-button'><span><Link className="tm-view-link" to={tm.url}>{t("readmore")} <i className='fa-solid fa-arrow-right'></i></Link></span></button></div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default HairTransplant