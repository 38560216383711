import React from 'react'
import './HomeImage.css'

import image_1 from '../assets/hi1.jpeg';
import image_2 from '../assets/hi2.jpeg';
import image_3 from '../assets/hi3.jpeg';
import image_4 from '../assets/hi4.jpeg';
import image_5 from '../assets/hi5.jpeg';
import image_6 from '../assets/hi6.jpeg';


import { useTranslation } from "react-i18next";

const HomeImage = () => { 

  const isMobile = window.innerWidth <= 767;
  const {t , i18n} = useTranslation();

  return (
    <div>
      {isMobile ? (
        <div className='mobile-container'>
          <p>{t("hheading")}</p>
          <img src={image_1} alt=""/>
          <img src={image_3} alt=""/>
          <img src={image_4} alt=""/>
          <img src={image_6} alt=""/>
        </div>
        ) : (
          <div className='container'>
            <div className='hi-ht'>
              <h1 className='hi-header'>{t("home-img1")}</h1>
              <p>{t("home-img2")}</p>
            </div>
            <div className='box'>
                <span><img src={image_6} alt=""/></span>
                <span><img src={image_5} alt=""/></span>
                <span><img src={image_4} alt=""/></span>
                <span><img src={image_3} alt=""/></span>
                <span><img src={image_2} alt=""/></span>
                <span><img src={image_1} alt=""/></span>
            </div>
          </div>
        )}
    </div>
  );
}

export default HomeImage


