import React from 'react'
import "./ImprintData.css"
import { useTranslation } from "react-i18next";

const ImprintData = () => {
  const {t , i18n} = useTranslation();
  return (
    <div className="body">
        <div>
            <h1>{t("imprint1")}</h1>
            <h4>{t("imprint2")}</h4>
            <p>*******</p>
            <p><strong>{t("imprint3")} </strong>{t("imprint4")}</p>
        </div>
        <div>
            <h1>{t("imprint5")}</h1>
            <h4>2023 Hair Land – Hairtransplant Istanbul</h4>
            <p><strong>{t("imprint6")}</strong>Bahattin Şimşek</p>
            <p><strong>{t("imprint7")}</strong> Huzur Mh., Azerbaycan Cd. No:4, 34415 Sarıyer/Istanbul</p>
            <p><strong>{t("imprint8")}</strong> +90 530 604 8558</p>
            <p><strong>E-Mail:</strong> info@hairlandistanbul.com</p>
        </div>
        <div className="legal">
            <h1>{t("imprint9")}</h1>
            <h4>§ {t("imprint10")}</h4>
            <p>{t("imprint11")}</p>
            <h4>§ {t("imprint12")}</h4>
            <p>{t("imprint13")}</p>
            <h4>§ {t("imprint14")}</h4>
            <p>{t("imprint15")}</p>
            <h4>§ {t("imprint16")}</h4>
            <p>{t("imprint17")}</p>
            <h4>§ {t("imprint18")}</h4>
            <p>{t("imprint19")}</p>
            <p>{t("imprint20")}</p>
            <h4>§ {t("imprint21")}</h4>
            <p>{t("imprint22")}</p>
        </div>
    </div>
  )
}

export default ImprintData