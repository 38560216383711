import React from 'react';
import "./Techniques.css"
import TechniquesData from "./TechniquesData.js"
import Fue1 from "../assets/fue1.jpg"
import Fue2 from "../assets/fue2.jpg"
import Fue3 from "../assets/fue3.jpg"
import { useTranslation } from "react-i18next";


const Fue = () => {
      const {t , i18n} = useTranslation();
  return (
    <div className='techniques'>
        <TechniquesData
        className="first-technique"
        heading={t("fuehtheading1")}
        heading1=""
        text={t("fuehttext1")}
        img1={Fue1}
        />

        <TechniquesData
        className="first-technique-reverse"
        heading={t("fuehtheading2")}
        heading1={t("fuehtheading3")}
        text={t("fuehttext2")}
        img1={Fue2}
        />

        <TechniquesData
        className="first-technique"
        heading={t("fuehtheading4")}
        text={t("fuehttext3")}
        img1={Fue3}
        />
    </div>
  )
}

export default Fue