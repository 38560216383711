import HairT from "../assets/hairT.jpg"
import BeardT from "../assets/beardT.jpg"
import EyebrowT from "../assets/eyebrowT.jpg"

export const tmData = [
    {
      image: HairT,
      heading: "hairt",
      url: "/hair-transplant"
    },
    {
      image: BeardT,
      heading: "beardt",
      url: "/beard-transplant"
    },
    {
        image: EyebrowT,
        heading: "eyebrowt",
        url: "/eyebrow-transplant"
      },
    
  ];
  
  
  
  