import React from 'react'
import './AboutUs.css'
import {auData} from './auData'
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const {t , i18n} = useTranslation();
  return (
    <div className='about-us-container'>
        <div className="about-us">
                {auData.map((au) =>
                    <div className="about">     
                        <ul>
                            <CheckIcon style={{fontSize : '2rem' , color : '#9BC0DE'}}/><li>{t(au.text)}</li>
                        </ul>
                    </div>
                )}
            </div>
    </div>
  )
}

export default AboutUs