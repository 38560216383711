import React from "react"
import "./Techniques.css";


function TechniquesData(props){
        return(
            <div className={props.className}>
                <div className="technique-text">
                    <h2>{props.heading}</h2>
                    <h4>{props.heading1}</h4>
                    <p>{props.text}</p>
                </div>
                <div className="image-t">
                    <img src={props.img1}/>
                </div>
        </div>
        )
}

export default TechniquesData;