import React , {useState} from 'react'
import './Testimonials.css'
import {testimonialsData} from './testimonialsData'
import {Link} from "react-router-dom"
import { useTranslation } from "react-i18next";

const Testimonials = () => {
    const transition = {type : 'spring' , duration : 3};
    const[selected,setSelected] = useState(0);
    const tLength = testimonialsData.length;

    const {t , i18n} = useTranslation();

    return (
        <div className="testimonials">
            <div className="left-t">
                <span>{t("testi")}</span>
                <span>{t("wthey")}</span>
                <span>{t("sayus")}</span>
                <span
                    key = {selected}
                    initial = {{opacity : 0 , x : -100}}
                    animate = {{opacity : 1 , x : 0}}
                    exit = {{opacity : 0 , x : 100}}
                    transition = {transition}
                >
                    {t(testimonialsData[selected].review)}
                </span>
                <span>
                    <span style = {{color: 'var(--orange)'}}>
                        {testimonialsData[selected].name}
                    </span>{" "}
                    
                </span>
                {/* <Link to="/before-after" className='before-after'>More Before-After</Link> */}
            </div>
            <div className="right-t">
            <div
                initial = {{opacity : 0 , x : -100}}
                transition = {{...transition , duration : 2}}
                whileinview = {{opacity : 1 , x : 0}}
            ></div>
            <div
                initial = {{opacity : 0 , x : 100}}
                transition = {{...transition , duration : 2}}
                whileinview = {{opacity : 1 , x : 0}}
            ></div>
            
                <img
                draggable="false"
                key = {selected}
                initial = {{opacity : 0 , x : 100}}
                animate = {{opacity : 1 , x : 0}}
                exit = {{opacity : 0 , x : -100}}
                transition = {transition}
                src = {testimonialsData[selected].image} alt="" />

                <div className="arrows">
                    <i 
                    className='fa-solid fa-arrow-left'
                    onClick={()=>{
                        selected ===0?setSelected(tLength-1): setSelected((prev)=>prev-1)
                    }}                   
                    ></i>
                    <i
                    className='fa-solid fa-arrow-right'
                    onClick={()=>{
                        selected===tLength-1 ?setSelected(0): setSelected((prev)=>prev+1)
                    }}
                    ></i>
                </div>
                
            </div>
            
        </div>
    )
}

export default Testimonials