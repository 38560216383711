import React from 'react'
import "./PrivacyData.css"
import { useTranslation } from "react-i18next";

const PrivacyData = () => {
  const {t , i18n} = useTranslation();
  return (
    <div className='p-body'>
        <h1 className='p-heading'>{t("privacy1")}</h1>
        <h2>{t("privacy2")}</h2>
        <h4>{t("privacy3")}</h4>
        <h3>{t("privacy4")}</h3>
        <p>{t("privacy5")}</p>
        <h3>{t("privacy6")}</h3>
        <p><strong>{t("privacy7")}</strong> {t("privacy8")}</p>
        <p><strong>{t("privacy9")}</strong> {t("privacy10")}</p>
        <h3>{t("privacy11")}</h3>
        <p>{t("privacy12")}</p>
        <h3>{t("privacy13")}</h3>
        <p>{t("privacy14")}</p>
        <h3>{t("privacy15")}</h3>
        <p>{t("privacy16")}</p>
        <h3>{t("privacy17")}</h3>
        <p>{t("privacy18")}</p>
        <h3>{t("privacy19")}</h3>
        <p>{t("privacy20")}</p>
        <h3>{t("privacy21")}</h3>
        <p>{t("privacy22")}</p>       
    </div>
  )
}

export default PrivacyData