import { Swiper, SwiperSlide } from 'swiper/react';
import image1 from "../assets/carousel1.png";
import image2 from "../assets/hosslider2.jpeg";
import image3 from "../assets/hosslider3.jpeg";
import image4 from "../assets/hosslider4.jpeg";
import image5 from "../assets/hosslider5.jpeg";
import image6 from "../assets/hosslider6.jpeg";

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { height } from '@mui/system';


export default () => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={3}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide><img src={image1} style={{maxHeight : '300px'}}/></SwiperSlide>
      <SwiperSlide><img src={image2} style={{maxHeight : '300px'}}/></SwiperSlide>
      <SwiperSlide><img src={image4} style={{maxHeight : '300px'}}/></SwiperSlide>
      <SwiperSlide><img src={image3} style={{maxHeight : '300px'}}/></SwiperSlide>
      <SwiperSlide><img src={image5} style={{maxHeight : '300px'}}/></SwiperSlide>
      <SwiperSlide><img src={image6} style={{maxHeight : '300px'}}/></SwiperSlide>
    </Swiper>
  );
};