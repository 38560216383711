import React , {useState} from 'react'
import {Link} from "react-router-dom"
import {useForm} from "react-hook-form"
import "./FHAForm.css"
import {collection , addDoc , setDoc , doc} from "firebase/firestore"
import Male from "../assets/fha-male.svg"
import Female from "../assets/fha-female.svg"
import Head1 from "../assets/male-head-style2.svg"
import Head2 from "../assets/male-head-style3.svg"
import Head3 from "../assets/male-head-style4.svg"
import Head4 from "../assets/male-head-style5.svg"
import Head5 from "../assets/male-head-style6.svg"
import Head6 from "../assets/male-head-style7.svg"
import LossTime1 from "../assets/hair-loss-time-1.svg"
import LossTime2 from "../assets/hair-loss-time-2.svg"
import LossTime3 from "../assets/hair-loss-time-3.svg"
import LossTime4 from "../assets/hair-loss-time-4.svg"
import App1 from "../assets/appointment-1.svg"
import App2 from "../assets/appointment-2.svg"
import App3 from "../assets/appointment-3.svg"
import App4 from "../assets/appointment-4.svg"
import { storeFHA } from '../Firebase'

import { useTranslation } from "react-i18next";
 
const FHAForm = () => {

  const {t , i18n} = useTranslation();
  
  const [formStep , setFormStep] = useState(0);
  const nextFormStep = () => {
    setFormStep(cur => cur+1)
  }

  const prevFormStep = () => {
    setFormStep(cur => cur-1)
  }

  const {watch , register , formState: {errors , isValid}} = useForm({mode: "all"});

  return (
    <div className='fha-container'>
        <div className='right-c'>
          <form>
            {formStep === 0 &&
            <section>
              <span className='step'>{t("step")} 1 {t("of")} 4</span>
              <h1>{t("gender")}</h1>
              <div className='radios'>
                <div className='radio'>
                  <img src={Male} draggable="false"/>
                  <label>{t("male")}</label>
                  <input type="radio" 
                  name='gender'
                  value='male' 
                  {...register('gender' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={Female} draggable="false"/>
                  <label>{t("female")}</label>
                  <input 
                  type="radio" 
                  name='gender'
                  value='female' 
                  {...register('gender' , {required : true})}/>
                </div>
              </div>
            </section>}
            {formStep === 1 &&
            <section>
              <span>{t("step")} 2 {t("of")} 4</span>
              <h1>{t("hairl")}</h1>
              <div className='radios'>
                <div className='radio'>
                  <img src={Head1} draggable="false"/>
                  <input 
                  type="radio" 
                  name="hair-loss"
                  value="first"
                  {...register('hair-loss' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={Head2} draggable="false"/>
                  <input
                   type="radio"
                    name="hair-loss"
                    value="second"
                    {...register('hair-loss' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={Head3} draggable="false"/>
                  <input
                   type="radio"
                    name="hair-loss"
                    value="third"
                    {...register('hair-loss' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={Head4} draggable="false"/>
                  <input
                   type="radio"
                    name="hair-loss"
                    value="fourth"
                    {...register('hair-loss' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={Head5} draggable="false"/>
                  <input
                   type="radio"
                    name="hair-loss"
                    value="fifth"
                    {...register('hair-loss' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={Head6} draggable="false"/>
                  <input
                   type="radio"
                    name="hair-loss"
                    value="sixth"
                    {...register('hair-loss' , {required : true})}/>
                </div>
              </div>
            </section>}
            {formStep === 2 &&
            <section>
              <span>{t("step")} 3 {t("of")} 4</span>
              <h1>{t("hairlt")}</h1>
              <div className='radios'>
                <div className='radio'>
                  <img src={LossTime1} draggable="false"/>
                  <label>{t("time1")}</label>
                  <input
                   type="radio"
                    name='loss-time'
                    value = "less than 1 year"
                    {...register('loss-time' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={LossTime2} draggable="false"/>
                  <label>{t("time2")}</label>
                  <input
                   type="radio"
                    name='loss-time'
                    value = "1-3 years"
                    {...register('loss-time' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={LossTime3} draggable="false"/>
                  <label>{t("time3")}</label>
                  <input
                   type="radio"
                   name='loss-time'
                   value = "3-5 years"
                   {...register('loss-time' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={LossTime4} draggable="false"/>
                  <label>{t("time4")}</label>
                  <input
                   type="radio"
                    name='loss-time'
                    value = "5+ years"
                    {...register('loss-time' , {required : true})}/>
                </div>
              </div>
              <h1 className='space-bet'>{t("app")}</h1>
              <div className='radios'>
                <div className='radio'>
                  <img src={App1} draggable="false"/>
                  <label>{t("app1")}</label>
                  <input 
                  type="radio" 
                  name='appointment'
                  value = "as soon as possible"
                    {...register('appointment' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={App2} draggable="false"/>
                  <label>{t("app2")}</label>
                  <input 
                  type="radio" 
                  name='appointment'
                  value = "In 3 months"
                    {...register('appointment' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={App3} draggable="false"/>
                  <label>{t("app3")}</label>
                  <input 
                  type="radio" 
                  name='appointment'
                  value = "In 1 year"
                    {...register('appointment' , {required : true})}/>
                </div>
                <div className='radio'>
                  <img src={App4} draggable="false"/>
                  <label>{t("app4")}</label>
                  <input 
                  type="radio" 
                  name='appointment'
                  value = "not planned yet"
                    {...register('appointment' , {required : true})}/>
                </div>
              </div>
            </section>}
            {formStep === 3 &&
            <section>
              <span>{t("step")} 4 {t("of")} 4</span>
              <h1>{t("form")}</h1>
              <div className='input-groups'>
                <input className="input"
                 type="text"
                  id="name"
                  name='name'
                  placeholder={t("formp1")} 
                  {...register('name' , {required:{
                  value : true,
                  message : t("fmes1")
                  }})}/>
                {errors.name && <p className='error-message'>{errors.name.message}</p>}
                <input className='input' 
                type="tel" 
                id="phone" 
                name='phone' 
                placeholder={t("formp2")}
                {...register('phone' , {required: {
                  value: true,
                  message : t("fmes2")
                }})}/>
                {errors.phone && <p className='error-message'>{errors.phone.message}</p>}
                <input className='input' 
                type='email' 
                id='email' 
                name='email' 
                placeholder='E-mail' 
                {...register('email' , {required: {
                  value : true,
                  message : t("fmes3")
                }})}/>
                {errors.email && <p className='error-message'>{errors.email.message}</p>}
                <button 
                  onClick={nextFormStep}
                  className='fha-button'
                >{t("formsend")}</button>
              </div>

              
              
            </section>}
            {formStep === 4 &&
            <section className='sent-section'>
              <h1>{t("sucsend")}</h1>
              <Link to="/"><button>{t("rethome")}</button></Link>
            </section>}

            {formStep === 0 && (
            <div className='form-button'>
              <div className='next-button'>
                <button
                  disabled = {!isValid}
                  onClick={nextFormStep}
                  type="button"
                  className='fha-button'
                >{t("nexts")}</button>
              </div>
            </div>
            )}

            {formStep === 4 && (
            <div className='form-button'>
              <div className='prev-button'>
                <button
                  onClick={prevFormStep}
                  type="button"
                  className='fha-button'
                >{t("prev")}</button>
              </div>
            </div>
            )}

            {formStep > 0 && formStep < 4 && (
            <div className='form-button'>
              <div className='prev-button'>
                <button
                  onClick={prevFormStep}
                  type="button"
                  className='fha-button'
                >{t("prevs")}</button>
              </div>
              <div className='next-button'>
                <button
                  disabled = {!isValid}
                  onClick={nextFormStep}
                  type="button"
                  className='fha-button'
                >{t("nexts")}</button>
              </div>
            </div>
            )}
          </form>
        </div>
    </div>
  )
}

export default FHAForm