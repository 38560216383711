import React from 'react'
import './WhatIsWaiting.css'
import CheckIcon from '@mui/icons-material/Check';
import WaitingBack from '../assets/waiting-back.jpeg'
import { useTranslation } from "react-i18next";

const WhatIsWaiting = () => {
  const {t , i18n} = useTranslation();
  return (
    <div className='wiw-container' style={{backgroundImage : `url(${WaitingBack})`}}>
        <h1>{t("wiwheading")}</h1>
        <ul>
            <li><CheckIcon style={{fontSize : '2rem' , color : '#2E6A97'}}/> {t("wiw1")}</li>
            <li><CheckIcon style={{fontSize : '2rem' , color : '#2E6A97'}}/> {t("wiw2")}</li>
            <li><CheckIcon style={{fontSize : '2rem' , color : '#2E6A97'}}/> {t("wiw3")}</li>
            <li><CheckIcon style={{fontSize : '2rem' , color : '#2E6A97'}}/> {t("wiw4")}</li>
            <li><CheckIcon style={{fontSize : '2rem' , color : '#2E6A97'}}/> {t("wiw5")}</li>
            <li><CheckIcon style={{fontSize : '2rem' , color : '#2E6A97'}}/> {t("wiw6")}</li>
            <li><CheckIcon style={{fontSize : '2rem' , color : '#2E6A97'}}/> {t("wiw7")}</li>
            <li><CheckIcon style={{fontSize : '2rem' , color : '#2E6A97'}}/> {t("wiw8")}</li>
        </ul>
    </div>
  )
}

export default WhatIsWaiting