export const auData = [
    {
        text : "au-text1"
    },
    {
        text : "au-text2"
    },
    {
        text : "au-text3"
    },
    {
        text : "au-text4"
    },
    {
        text : "au-text5"
    },
    {
        text : "au-text6"
    },
  ];
  