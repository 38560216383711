import React from 'react'
import './Wrapper.css'
import UpdateIcon from '@mui/icons-material/Update';
import EuroIcon from '@mui/icons-material/Euro';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ComputerIcon from '@mui/icons-material/Computer';
import { useTranslation } from "react-i18next";

const Wrapper = () => {
    const {t , i18n} = useTranslation();
  return (
    <div className = 'wrapper-container'>
        <div className="wrappers">
            <div className="wrapper">                       
                <UpdateIcon style={{fontSize : '3rem' , color : '#9BC0DE'}}/>
                <span className='w-title'>{t("wrap-title1")}</span>
                <span>{t("wrap-text1")}</span>
            </div>
        </div>
        <div className="wrappers">
            <div className="wrapper">                       
                <EuroIcon style={{fontSize : '3rem' , color : '#9BC0DE'}}/>
                <span className='w-title'>{t("wrap-title2")}</span>
                <span>{t("wrap-text2")}</span>
            </div>
        </div>
        <div className="wrappers">
            <div className="wrapper">                       
                <HandshakeIcon style={{fontSize : '3rem' , color : '#9BC0DE'}}/>
                <span className='w-title'>{t("wrap-title3")}</span>
                <span>{t("wrap-text3")}</span>
            </div>
        </div>
        <div className="wrappers">
            <div className="wrapper">                       
                <ComputerIcon style={{fontSize : '3rem' , color : '#9BC0DE'}}/>
                <span className='w-title'>{t("wrap-title4")}</span>
                <span>{t("wrap-text4")}</span>
            </div>
        </div>
    </div>
  )
}

export default Wrapper