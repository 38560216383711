import React from 'react';
import {Link} from "react-router-dom"
import './HairTransplant.css';
import {htData} from './htData';
import { useTranslation } from "react-i18next";

const HairTransplant = () => {
    const {t , i18n} = useTranslation();
    return (
        <div className="h-transplant" id="h-transplant">
            <div className="ht-header">
                <h3>{t("extech")}</h3>
            </div>

            <div className="ht-categories">
                {htData.map((ht) =>
                    <div className="category">
                        
                        <span>{t(ht.heading)}</span>
                        <span>{t(ht.details)}</span>
                        <div className="view-more"><button className='ht-button'><span><Link className="view-link" to={ht.url}>{t("viewmore")}</Link><i className='fa-solid fa-arrow-right'></i></span></button></div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default HairTransplant