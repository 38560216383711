import React from 'react'
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import FAQImage from "../assets/FAQImage.jpg"
import Hero from "../components/Hero.js"
import Footer from "../components/Footer.js"
import Accordion from "../components/Accordion.js"
import {faqData} from "../components/faqData"
import "../components/Accordion.css"

const FAQ = () => {
  return (
    <>
    <Info/>
    <Navbar/>
    <Hero
    cName="hero-mid"
    heroImg={FAQImage}
    />
    <div>
            <div className='main-title-underline'></div>
            {faqData.map((section, index) =>(
                <Accordion key={index} section={section}/>
            ))}
    </div>
    <Footer/>
    
    </>
  )
}

export default FAQ