import image1 from "../assets/himage1.jpeg";
import image2 from "../assets/himage2.jpeg";
import image3 from "../assets/himage4.jpeg";
import image4 from "../assets/himage3.jpeg";


export const hospitalData = [
  {
    image: image1,
  },
  {
    image: image2,
  },
  {
    image : image3,
  },
  {
    image : image4,
  },
];

