import React from "react";
import "./HtHero.css"
import {Link} from "react-router-dom"

function HtHero (props){
    return(
        <>
            <div className={props.cName}>
                <img src={props.heroImg}/>
                <div className="hthero-text">
                    <h1>{props.title}</h1>
                    <p>{props.text}</p>
                    <Link to="/fha" className={props.btnClass}>{props.buttonText}</Link>
                </div>
            </div>
        </>
    )
}

export default HtHero;