import React from "react";
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import AboutImage from "../assets/about-hero.png"
import Hero from "../components/Hero.js"
import Footer from "../components/Footer.js"
import HairTransplant from "../components/HairTransplant.js";
import TreatmentMethods from "../components/TreatmentMethods.js";
import Comment from "../components/Comment.js";
import WhatIsWaiting from "../components/WhatIsWaiting.js"
import AboutUs from '../components/AboutUs.js'
import Wrapper from '../components/Wrapper.js'
import Us from '../components/Us.js'

function About (){
    return(
        <>
            <Info/>
            <Navbar/>
            <Hero
            cName="hero"
            heroImg={AboutImage}
            title=""
            />
            <AboutUs/>
            <Wrapper/>
            <HairTransplant/>
            <WhatIsWaiting/>
            <TreatmentMethods/>
            <Us/>
            <Comment/>
            <Footer/>
        </>
    )
}

export default About;