import React from 'react'
import useOpenController from "./useOpenController"
import "./HomeAccordion.css"
import { useTranslation } from "react-i18next";

const HomeAccordion = ({section}) => {
  const {isOpen , toggle} = useOpenController(false);
  const {t , i18n} = useTranslation();

  return (
    <div className='accordion-container'>
        <ExpendableColumn 
          question={section.question} 
          isOpen={isOpen} 
          toggle={toggle}
        />
        {isOpen && <TextSection answer={section.answer}/>}
        <div className="underline"></div>
    </div>
  )
}

export default HomeAccordion

export const ExpendableColumn = ({question , isOpen , toggle}) => {
  const {t , i18n} = useTranslation();
  return(
     <div className='column-container' onClick = {toggle}>
      <div className='column-text'>{t(question)}</div>
      <button className="expendable-button">
        <span 
          className="material-symbols-outlined" 
          style={{transform: `rotate(${isOpen ? 180 : 0}deg)` , transition: "all 0.25s"}}
        >
          expand_more
        </span>
      </button>
    </div>
  );
};

export const TextSection = ({answer }) => {
  const {t} = useTranslation();
  return( 
  <div className='text-container'>
      <div>{t(answer)}</div>
  </div>
  );
};