import React from 'react'
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import PrivacyImage from "../assets/PrivacyImage.jpg"
import Hero from "../components/Hero.js"
import Footer from "../components/Footer.js"
import PrivacyData from "../components/PrivacyData.js"

const Privacy = () => {
  return (
    <>
    <Info/>
    <Navbar/>
    <Hero
    cName="hero-mid"
    heroImg={PrivacyImage}
    />
    <PrivacyData/>
    <Footer/>
    </>
  )
}

export default Privacy