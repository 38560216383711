import React , {useState} from 'react'
import "./HospitalInfo.css"
import {hospitalData} from "./hospitalData"

 
const HospitalInfo = () => {
    const transition = {type : 'spring' , duration : 3};
    const[selected,setSelected] = useState(0);
    const tLength = hospitalData.length;
  return (
    <div className="hospital-info">
            <div className="hospital">
            
                <img
                className='hospital-img'
                draggable="false"
                key = {selected}
                initial = {{opacity : 0 , x : 100}}
                animate = {{opacity : 1 , x : 0}}
                exit = {{opacity : 0 , x : -100}}
                transition = {transition}
                src = {hospitalData[selected].image} alt="Memorial" />

                

                <div className="h-arrows">
                    <i 
                    className='fa-solid fa-arrow-left'
                    onClick={()=>{
                        selected ===0?setSelected(tLength-1): setSelected((prev)=>prev-1)
                    }}                   
                    ></i>
                    <i
                    className='fa-solid fa-arrow-right'
                    onClick={()=>{
                        selected===tLength-1 ?setSelected(0): setSelected((prev)=>prev+1)
                    }}
                    ></i>
                </div>
            </div>
        </div>
  )
}

export default HospitalInfo