import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyAE5gGUt4mts0zEYavOfYZ-it6PEdenNqs",
  authDomain: "hair-land-5d016.firebaseapp.com",
  projectId: "hair-land-5d016",
  storageBucket: "hair-land-5d016.appspot.com",
  messagingSenderId: "632074566431",
  appId: "1:632074566431:web:f1fb12095664c7a0418e2a"
};


const app = initializeApp(firebaseConfig);

const storeFHA = getFirestore(app);

export {storeFHA}