import React from "react";
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import HtHero from "../components/HtHero.js"
import Footer from "../components/Footer.js"
import HTBody from "../components/HTBody.js"
import HairTransplant from "../components/HairTransplant.js"
import TreatmentMethods from "../components/TreatmentMethods.js"
import { useTranslation } from "react-i18next";

function HairTransplantation (){

    const {t , i18n} = useTranslation();
    return(
        <>
        <Info/>
        <Navbar/>
        <HtHero
        cName="hero-ht"
        title={t("httitle")}
        text={t("httext")}
        buttonText={t("freeha")}
        url="/freehairanalysis"
        btnClass="fha"
        />
        <HTBody/>
        <HairTransplant/>
        <TreatmentMethods/>
        <Footer/>
        </>    
    )
}

export default HairTransplantation;