import AvatarImg from "../assets/avatar.png"

export const cData = [
    {
        id: 1,
        comment: "comment1",
        avatar: AvatarImg,
        user: "Marcel Seidl",
        date: "c-time1"
    },
    {
        id: 2,
        comment: "comment2",
        avatar: AvatarImg,
        user: "Nadja Blum",
        date: "c-time2"
    },
    {
        id: 3,
        comment: "comment3",
        avatar: AvatarImg,
        user: "Moritz Stein",
        date: "c-time3"
    },
    {
        id: 4,
        comment: "comment4",
        avatar: AvatarImg,
        user: "Kevin Krause",
        date: "c-time4"
    },
    {
        id: 5,
        comment: "comment5",
        avatar: AvatarImg,
        user: "Robert Olyzck",
        date: "c-time5"
    }
  ];
  
  
  
  