import React from "react";
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import HtHero from "../components/HtHero.js"
import Footer from "../components/Footer.js"
import TreatmentMethods from "../components/TreatmentMethods.js"
import HairTransplant from "../components/HairTransplant.js"
import BTBody from "../components/BTBody.js"
import { useTranslation } from "react-i18next";

function BeardTransplant (){

    const {t , i18n} = useTranslation();

    return(
        <>
        <Info/>
        <Navbar/>
        <HtHero
        cName="hero-ht"
        title={t("bttitle")}
        text={t("bttext")}
        buttonText={t("freeha")}
        url="/freehairanalysis"
        btnClass="fha"
        />
        <BTBody/>
        <HairTransplant/>
        <TreatmentMethods/>
        <Footer/>
        </>    
    )
}

export default BeardTransplant;