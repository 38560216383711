import React from "react";
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import Footer from "../components/Footer.js"
import HtHero from "../components/HtHero.js"
import SDHI from "../components/SDHI.js"
import HairTransplant from "../components/HairTransplant.js";
import { useTranslation } from "react-i18next";

function SDHIHT (){
    const {t , i18n} = useTranslation();

    return(
        <>
        <Info/>
        <Navbar/>
        <HtHero
        cName="hero-ht"
        title={t("sdhihttitle")}
        text={t("sdhihttext")}
        buttonText={t("freeha")}
        url="/freehairanalysis"
        btnClass="fha"
        />
        <SDHI/>
        <HairTransplant/>
        <Footer/>
        </>    
    )
}

export default SDHIHT;