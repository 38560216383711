import back1 from "../assets/back1.jpg"

export const htData = [
    {
      image: back1,
      heading: "fue",
      details: "fuet",
      url: "/fue"
    },
    {
      
      heading: "sapphire",
      details:
        "sapphiret",
      url: "/sapphire"
    },
    {
      image: back1,
      heading: "dhi",
      details:
        "dhit",
      url: "/dhi"
    },
  ];
  
  
  
  