export const MenuItems = [
    {
        title : 'home',
        url : "/",
        cName : "nav-links",
    },

    {
        title : "about",
        url : "/about",
        cName : "nav-links",
    },

    {
        title : "hospital",
        url : "/hospital",
        cName : "nav-links",
    },

    {
        title : "contact",
        url : "/contact",
        cName : "nav-links",
    },

    {
        title : "freeha",
        url : "/freehairanalysis",
        cName : "nav-links-mobile",
    },
]