import React from 'react';
import "./Techniques.css"
import TechniquesData from "./TechniquesData.js"
import Per1 from "../assets/percutaneous1.jpeg"
import Per2 from "../assets/percutaneous2.jpeg"
import Per3 from "../assets/percutaneous3.jpeg"
import { useTranslation } from "react-i18next";


const Percutaneous = () => {
      const {t , i18n} = useTranslation();
  return (
    <div className='techniques'>
        <TechniquesData
        className="first-technique"
        heading={t("percuhtheading1")}
        heading1={t("percuhtheading2")}
        text={t("percuhttext1")}
        img1={Per1}
        />

        <TechniquesData
        className="first-technique-reverse"
        heading={t("percuhtheading3")}
        heading1={t("percuhtheading4")}
        text={t("percuhttext2")}
        img1={Per2}
        />

        <TechniquesData
        className="first-technique"
        heading={t("percuhtheading5")}
        heading1=""
        text={t("percuhttext3")}
        img1={Per3}
        />
    </div>
  )
}

export default Percutaneous