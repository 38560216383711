import image1 from "../assets/testimonials1.JPG";
import image2 from "../assets/testimonials2.JPG";
import image3 from "../assets/testimonials3.JPG";
import image4 from "../assets/testimonials4.JPG";
import image5 from "../assets/testimonials5.JPG";
import image7 from "../assets/testimonials7.jpg";
import image8 from "../assets/testimonials8.jpg";


export const testimonialsData = [
  {
    image: image1,
    review:
      "test1",
    name: 'Sascha',
  },
  {
    image: image2,
    review: "test2",
    name: 'Ersin',
  },
  {
    image : image3,
    review:"test3",
    name: 'Mehmet',
  },
  {
    image : image4,
    review:"test4",
    name: 'Marcel O.',
  },
  {
    image : image5,
    review:"test5",
    name: 'Marc S.',
  },
  {
    image : image7,
    review:"test7",
    name: 'Stefan H.',
  },
  {
    image : image8,
    review:"test8",
    name: 'Melissa Z.',
  },
];
