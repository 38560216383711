import React from 'react';
import "./Techniques.css"
import TechniquesData from "./TechniquesData.js"
import Hair1 from "../assets/hairF.jpg"
import Hair2 from "../assets/hairS.jpg"
import Hair3 from "../assets/hairThird.jpg"
import { useTranslation } from "react-i18next";

const HTBody= () => {
      const {t , i18n} = useTranslation();
  return (
    <div className='techniques'>
        <TechniquesData
        className="first-technique"
        heading={t("htheading1")}
        heading1=""
        text={t("httext1")}
        img1={Hair1}
        />

        <TechniquesData
        className="first-technique-reverse"
        heading={t("htheading2")}
        heading1={t("htheading3")}
        text={t("httext2")}
        img1={Hair2}
        />

        <TechniquesData
        className="first-technique"
        heading={t("htheading4")}
        heading1={t("htheading5")}
        text={t("httext3")}
        img1={Hair3}
        />
    </div>
  )
}

export default HTBody