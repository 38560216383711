import React from 'react'
import './Us.css'
import UsImage from '../assets/hos4.jpg'
import AddIcon from '@mui/icons-material/Add';
import PercentIcon from '@mui/icons-material/Percent';
import NumberCounter from 'number-counter'
import { useTranslation } from "react-i18next";

const Us = () => {
    const {t , i18n} = useTranslation();
  return (
    <div className='us-container'>
        <div className='us-img'>
            <img src = {UsImage}/>
        </div>
        <div className='us-info'>
            <span className='us-text'>{t("us-title1")} <br/>{t("us-title2")}</span>
            <div className='us-icons'>
                <div className='us-icon'>
                    <span className='us-text us-number'><NumberCounter end = {15} start = {0} delay = '4' /> <AddIcon style={{fontSize : '3rem' , color : '#9BC0DE'}}/></span>
                    <span className='us-yepp'>{t("us-year")}</span>
                </div>
                <div className='us-icon'>
                    <span className='us-text us-number'><NumberCounter end = {100} start = {0} delay = '4' /><PercentIcon style={{fontSize : '3rem' , color : '#9BC0DE'}}/></span>
                    <span className='us-yepp'>{t("us-prof")}</span>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Us