import React from 'react';
import "./Techniques.css"
import TechniquesData from "./TechniquesData.js"
import Beard1 from "../assets/beardF.jpeg"
import Beard2 from "../assets/beardS.jpg"
import Beard3 from "../assets/bt1.jpeg"
import { useTranslation } from "react-i18next";

const BTBody = () => {
      const {t , i18n} = useTranslation();
  return (
    <div className='techniques'>
        <TechniquesData
        className="first-technique"
        heading={t("btheading1")}
        heading1=""
        text={t("bttext1")}
        img1={Beard1}
        />

        <TechniquesData
        className="first-technique-reverse"
        heading={t("btheading2")}
        heading1=""
        text={t("bttext2")}
        img1={Beard2}
        />

        <TechniquesData
        className="first-technique"
        heading=""
        heading1={t("btheading3")}
        text={t("bttext3")}
        img1={Beard3}
        />
    </div>
  )
}

export default BTBody