import React from 'react';
import "./Techniques.css"
import TechniquesData from "./TechniquesData.js"
import Anest1 from "../assets/anesthesia1.png"
import Anest2 from "../assets/anesthesia2.jpg"
import Anest3 from "../assets/anesthesia3.jpeg"
import { useTranslation } from "react-i18next";

const Anesthesia = () => {
  const {t , i18n} = useTranslation();
  return (
    <div className='techniques'>
        <TechniquesData
        className="first-technique"
        heading={t("htaheading1")}
        heading1=""
        text={t("htatext1")}
        img1={Anest1}
        />

        <TechniquesData
        className="first-technique-reverse"
        heading={t("htaheading2")}
        heading1=""
        text={t("htatext2")}
        img1={Anest2}
        />

        <TechniquesData
        className="first-technique"
        heading={t("htaheading3")}
        heading1=""
        text={t("htatext3")}
        img1={Anest3}
        />
    </div>
  )
}

export default Anesthesia