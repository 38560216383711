import React from "react";
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import Hero from "../components/Hero.js"
import HeroImage from "../assets/HeroImage.jpg"
import Why from "../components/Why.js"
import Footer from "../components/Footer.js"
import HomeImage from "../components/HomeImage.js"
import Testimonials from "../components/Testimonials.js"
import HomeAccordion from "../components/HomeAccordion.js"
import TreatmentMethods from "../components/TreatmentMethods.js"
import {homeFaqData} from "../components/homeFaqData"
import "../components/HomeAccordion.css"
import WhatIsWaiting from "../components/WhatIsWaiting.js"
import { useTranslation } from "react-i18next";
import HeroMobile from '../assets/hero-mobile.jpg'

function Home (){
    const isMobile = window.innerWidth <= 767;
    const {t , i18n} = useTranslation();

    return(
        <>
        <Info/>
        <Navbar/>
        <Hero
        cName="hero-home"
        heroImg= {isMobile ? HeroMobile : HeroImage}
        title={t("unlock")}
        text={t("hheading")}
        buttonText={t("service")}
        url="/hospital"
        btnClass="show"
        />
        <HomeImage/>
        <Why/>
        <WhatIsWaiting/>
        <TreatmentMethods/>
        <Testimonials/>
        <div>
            <h3 className="main-title">{t('freq')}</h3>
            <div className='main-title-underline'></div>
            {homeFaqData.map((section, index) =>(
                <HomeAccordion key={index} section={section}/>
            ))}
        </div>
        <Footer/>
        </>    
    )
}

export default Home;
