import "./App.css";
import React , {useEffect} from "react";
import {Route ,Routes , useLocation} from "react-router-dom";
import Home from "./routes/Home.js"
import Hospital from "./routes/Hospital.js"
import About from "./routes/About.js"
import Contact from "./routes/Contact.js"
import FAQ from "./routes/FAQ.js"
import Imprint from "./routes/Imprint.js"
import Privacy from "./routes/Privacy.js"
import FueHT from "./routes/FueHT.js"
import SapphireHT from "./routes/SapphireHT.js"
import SDHIHT from "./routes/SDHIHT.js"
import HTDHI from "./routes/HTDHI.js"
import PercutaneousHT from "./routes/PercutaneousHT.js"
import HTAnesthesia from "./routes/HTAnesthesia.js"
import FreeHairAnalysis from "./routes/FreeHairAnalysis.js"
import HairTransplantation from "./routes/HairTransplantation.js"
import BeardTransplant from "./routes/BeardTransplant.js"
import EyebrowTransplant from "./routes/EyebrowTransplant.js"
import BeforeAfter from "./routes/BeforeAfter.js"

function App() {

  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }
  
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/hospital" element={<Hospital/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/fha" element={<FreeHairAnalysis/>}/>
        <Route path="/faq" element={<FAQ/>}/>
        <Route path="/imprint" element={<Imprint/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/fue" element={<FueHT/>}/>
        <Route path="/sapphire" element={<SapphireHT/>}/>
        <Route path="/sdhi" element={<SDHIHT/>}/>
        <Route path="/percutaneous" element={<PercutaneousHT/>}/>
        <Route path="/anesthesia" element={<HTAnesthesia/>}/>
        <Route path="/dhi" element={<HTDHI/>}/>
        <Route path="/hair-transplant" element={<HairTransplantation/>}/>
        <Route path="/beard-transplant" element={<BeardTransplant/>}/>
        <Route path="/eyebrow-transplant" element={<EyebrowTransplant/>}/>
        <Route path="/before-after" element={<BeforeAfter/>}/>
      </Routes>
    </div>
  );
}

export default App;
