import React from 'react';
import "./Techniques.css"
import TechniquesData from "./TechniquesData.js"
import Dhi1 from "../assets/dhi1.jpeg"
import Dhi2 from "../assets/dhi2.jpeg"
import Dhi3 from "../assets/dhi3.jpeg"
import { useTranslation } from "react-i18next";


const DHI = () => {
      const {t , i18n} = useTranslation();
  return (
    <div className='techniques'>
        <TechniquesData
        className="first-technique"
        heading={t("dhihtheading1")}
        heading1=""
        text={t("dhihttext1")}
        img1={Dhi1}
        />

        <TechniquesData
        className="first-technique-reverse"
        heading=""
        heading1=""
        text={t("dhihttext2")}
        img1={Dhi2}
        />

        <TechniquesData
        className="first-technique"
        heading={t("dhihtheading2")}
        heading1={t("dhihtheading3")}
        text={t("dhihttext3")}
        img1={Dhi3}
        />
    </div>
  )
}

export default DHI