import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MenuItems } from "./MenuItems";
import "./Navbar.css";
import Logo from "../assets/logo.svg";
import World from "../assets/world.png";
import En from "../assets/eflag.png";
import De from "../assets/gflag.png";
import { useTranslation } from "react-i18next";


const Navbar = () => {
  const [dropdownActive, setDropdownActive] = useState(false);

  const handleDropdown = () => {
    setDropdownActive(() => !dropdownActive)
  }

  const [clicked, setClicked] = useState(false);

  const handleDropdownItemClick = () => {
    setDropdownActive(false);
  }

  const handleClick = () => {
    setClicked(!clicked);
  };

  const {t , i18n} = useTranslation();

  const clickHandle = async lang => {
    await i18n.changeLanguage(lang)
  }


  return (
    <nav className="NavbarItems">
      <img className="logo" src={Logo} alt="Logo" draggable='false'/>
      <div className="menu-icons" onClick={handleClick}>
        <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        {MenuItems.map((item, index) => (
          <li key={index}>
            <Link className={item.cName} to={item.url}>
              {t(item.title)}
            </Link>
          </li>
        ))}
        <Link to="/fha"><button className="nav-button">{t("freeha")}</button></Link>
        <div className="dropdown-wrapper">
          <button className="dd-button" onClick={handleDropdown}>
            <img src={World} alt="World" />
          </button>
          <div className={`${dropdownActive && 'show-dropdown'} dropdown-menu`}>
            <button className="dropdown-item" onClick={() => {clickHandle('de'); handleDropdownItemClick();}}>
              <img src={De} alt="De" />
            </button>
            <button className="dropdown-item" onClick={() => {clickHandle('en'); handleDropdownItemClick();}}>
              <img src={En} alt="En" />
            </button>
          </div>
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;
