import React from 'react'
import "./ContactForm.css"
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const isMobile = window.innerWidth <= 767;
  const {t , i18n} = useTranslation();
  return (
    <div className="form-container">
        <h1>{t("sendames")}</h1>
        <form>
            <input placeholder="Name"/>
            <input placeholder="Email"/>
            <input placeholder={t("psub")}/>
            <textarea placeholder={t("pmes")} rows="4"></textarea>
            <button>{t("sendmes")}</button>
        </form>
        <div className='map'>
        {isMobile ? (
          <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96286.11842750518!2d28.93086713465382!3d41.04841491168789!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab429b6331bc5%3A0x2b78435fb80a65f5!2sSkyland%20%C4%B0stanbul!5e0!3m2!1str!2str!4v1702236622766!5m2!1str!2str" 
          width="350" 
          height="250"
          style={{border: "0"}} 
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade">
          </iframe> ) : (
            <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96286.11842750518!2d28.93086713465382!3d41.04841491168789!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab429b6331bc5%3A0x2b78435fb80a65f5!2sSkyland%20%C4%B0stanbul!5e0!3m2!1str!2str!4v1702236622766!5m2!1str!2str" 
            width="1000" 
            height="450" 
            style={{border: "0"}} 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          )}
        </div>
    </div>
  )
}

export default ContactForm