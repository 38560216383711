import React from "react";
import Navbar from "../components/Navbar.js";
import Info from "../components/Info.js";
import AboutImage from "../assets/AboutImage.jpg"
import Hero from "../components/Hero.js"
import Footer from "../components/Footer.js"
import HairTransplant from "../components/HairTransplant.js";
import TreatmentMethods from "../components/TreatmentMethods.js";
import BABody from "../components/BABody.js"

function BeforeAfter (){
    return(
        <>
        <Info/>
        <Navbar/>
        <Hero
        cName="hero-mid"
        heroImg={AboutImage}
        title=""
        />
        {/* <BABody/> */}
        <HairTransplant/>
        <TreatmentMethods/>
        <Footer/>
        </>
    )
}

export default BeforeAfter;