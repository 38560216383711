export const homeFaqData = [
    {
        question: "hfaqq1",
        answer: "hfaqa1"
    },
    {
        question: "hfaqq2",
        answer: "hfaqa2"
    },
    {
        question: "hfaqq3",
        answer: "hfaqa3"
    },
];